import React, { Component } from 'react'
import { Table, TableContainer } from "./style";
import PropTypes from 'prop-types';
import TableRowUserFeature from './TableRowUserFeature';

const wellness = [
  {
    feature: 'Personalized Dashboard',
    starter: true,
    core: true,
    premium: true,
    dropdown: 1,
    value: 1,
  }, {
    feature: 'Activity & Wellness Challenges',
    starter: true,
    core: true,
    premium: true,
    dropdown: 1,
    value: 2,
  }, {
    feature: 'Wellness Resources',
    starter: true,
    core: true,
    premium: true,
    dropdown: 1,
    value: 3
  }, {
    feature: 'Community',
    starter: true,
    core: true,
    premium: true,
    dropdown: 1,
    value: 4
  }, {
    feature: 'Gamification',
    starter: true,
    core: true,
    premium: true,
    dropdown: 1,
    value: 5
  },
  {
    feature: 'Profile',
    starter: true,
    core: true,
    premium: true
  }, {
    feature: 'Knowledge Base Support',
    starter: true,
    core: true,
    premium: true
  }, {
    feature: 'Email + Notifications',
    starter: true,
    core: true,
    premium: true
  }, {
    feature: 'Global Leaderboard ',
    starter: false,
    core: true,
    premium: true
  }, {
    feature: 'Events',
    starter: false,
    core: true,
    premium: true,
    dropdown: 1,
    value: 6
  }, {
    feature: 'Monthly Wellness Newsletter',
    starter: false,
    core: true,
    premium: true
  }, {
    feature: 'Company Rewards',
    starter: false,
    core: false,
    premium: true
  }, {
    feature: 'Dedicated Support',
    starter: false,
    core: false,
    premium: true
  }, {
    feature: 'SMS Engagement + Wellness',
    starter: false,
    core: false,
    premium: true
  }
];
class TeamPackageTable extends Component {
  constructor() {
    super();
    this.state = {
      open: 0,
      close: false,
      dropdownOpen: null,
    }
  }

  readMore = (value) => {
    this.setState({ open: !this.state.open, close: !this.state.close, dropdownOpen: value });
  }

  render() {
    return (
      <TableContainer>
        <Table>
          {
            wellness.map((item, index) => (
              <TableRowUserFeature item={item} key={index} wellness={wellness} />
            ))
          }
        </Table>
      </TableContainer>
    );
  }
}
TeamPackageTable.propTypes = {
  tableClass: PropTypes.string,
  headerClass: PropTypes.string
};

export default TeamPackageTable;