import React, { Component } from 'react';
import { TeamTabContainer } from "./style";
import PropTypes from 'prop-types';
import TeamPackageTable from "./TeamPackageTable";
import TeamPackageTableAdmin from "./TeamPackageTableAdmin";

class TeamPackage extends Component {
  constructor() {
    super();
    this.state = {
      active: true
    }
  }

  toggleTabs = (status) => this.setState({ active: status });

  renderTabs = () => (
    <TeamTabContainer tabActive setTabWidth active={this.state.active}>
      <div>
        <div>
          <div onClick={() => this.toggleTabs(true)}>User Features</div>
          <div onClick={() => this.toggleTabs(false)}>Admin Features</div>
        </div>
      </div>
      <div>
        <div>Core</div>
        <div>Premium</div>
        <div>Enterprise</div>
      </div>
    </TeamTabContainer>
  );

  render() {
    const { active } = this.state;
    return (
      <div className="teamPackage">
        <div className="planTableContainer">
          {this.renderTabs()}
          {
            active ?
              <TeamPackageTable />
              :
              <TeamPackageTableAdmin />
          }
        </div>
      </div>
    );
  }
}

TeamPackage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default TeamPackage;