import React, { Component } from 'react'
import PropTypes from "prop-types";

const subcontent = [
  {
    subfeature: 'Enagement Data and Analytics',
    starter: true,
    core: true,
    premium: true,
    value: 1
  }, {
    subfeature: 'Fitness Data and Analytics',
    starter: true,
    core: true,
    premium: true,
    value: 1
  }, {
    subfeature: 'Wellness Champion Management',
    starter: true,
    core: true,
    premium: true,
    value: 1
  }, {
    subfeature: 'Admin Management',
    starter: true,
    core: true,
    premium: true,
    value: 1
  }, {
    subfeature: 'Manage Company Initiatives',
    starter: true,
    core: true,
    premium: true,
    value: 1
  }, {
    subfeature: 'Upcoming Monthly Themes',
    starter: true,
    core: true,
    premium: true,
    value: 1
  }, {
    subfeature: 'Top Fitness Activites Analysis',
    starter: true,
    core: true,
    premium: true,
    value: 1
  }, {
    subfeature: 'Active Wellness Participantion',
    starter: true,
    core: true,
    premium: true,
    value: 1
  }, {
    subfeature: 'Fitness Device Tracking',
    starter: true,
    core: true,
    premium: true,
    value: 1
  }, {
    subfeature: 'Top Wellness Goal Analysis',
    starter: true,
    core: true,
    premium: true,
    value: 1
  }, {
    subfeature: 'Downloadable Engagement Files',
    starter: true,
    core: true,
    premium: true,
    value: 1
  }, {
    subfeature: 'Downloadable Fitness Reports',
    starter: true,
    core: true,
    premium: true,
    value: 1
  },

  {
    subfeature: 'Challenge Analysis',
    starter: true,
    core: true,
    premium: true,
    value: 2
  }, {
    subfeature: 'Create Company Challenges',
    starter: true,
    core: true,
    premium: true,
    value: 2
  }, {
    subfeature: 'Track Completed Activity Challenges',
    starter: true,
    core: true,
    premium: true,
    value: 2
  }, {
    subfeature: 'Track Completed Wellness Behaviour Challenges',
    starter: true,
    core: true,
    premium: true,
    value: 2
  }, {
    subfeature: 'Top Challenge Participants',
    starter: true,
    core: true,
    premium: true,
    value: 2
  }, {
    subfeature: 'Top Challenge Leaderboard',
    starter: true,
    core: true,
    premium: true,
    value: 2
  }, {
    subfeature: 'Downloadable Challenge Reports',
    starter: true,
    core: true,
    premium: true,
    value: 2
  },

  {
    subfeature: 'Employee Directory',
    starter: true,
    core: true,
    premium: true,
    value: 3
  }, {
    subfeature: 'Advance Search',
    starter: true,
    core: true,
    premium: true,
    value: 3
  }, {
    subfeature: 'Employee Profile Pages',
    starter: true,
    core: true,
    premium: true,
    value: 3
  }, {
    subfeature: 'Add User Roles and Permissions',
    starter: true,
    core: true,
    premium: true,
    value: 3
  }, {
    subfeature: 'Add New Hires',
    starter: true,
    core: true,
    premium: true,
    value: 3
  }, {
    subfeature: 'Bulk CSV Upload Invites',
    starter: true,
    core: true,
    premium: true,
    value: 3
  }, {
    subfeature: 'Removing of Users',
    starter: true,
    core: true,
    premium: true,
    value: 3
  },

  {
    subfeature: 'Aggregated Real-Time Health Data Analysis',
    starter: false,
    core: true,
    premium: true,
    value: 4
  }, {
    subfeature: 'Company Wellness Score',
    starter: false,
    core: true,
    premium: true,
    value: 4
  }, {
    subfeature: 'Review and Compare Historical Data',
    starter: false,
    core: true,
    premium: true,
    value: 4
  }, {
    subfeature: 'View Potential Health Concerns',
    starter: false,
    core: true,
    premium: true,
    value: 4
  }, {
    subfeature: 'Analyze Workplace Wellbeing',
    starter: false,
    core: true,
    premium: true,
    value: 4
  }, {
    subfeature: 'Track Employee Participation',
    starter: false,
    core: true,
    premium: true,
    value: 4
  },

  {
    subfeature: 'Manage and Launch Company Events',
    starter: false,
    core: true,
    premium: true,
    value: 5
  }, {
    subfeature: 'Automated Emails and Notification',
    starter: false,
    core: true,
    premium: true,
    value: 5
  }, {
    subfeature: 'Customizables Emails and Branding',
    starter: false,
    core: true,
    premium: true,
    value: 5
  }, {
    subfeature: 'Downloadable Event Reports',
    starter: false,
    core: true,
    premium: true,
    value: 5
  }, {
    subfeature: 'Ability To Upload Flyers',
    starter: false,
    core: true,
    premium: true,
    value: 5
  }, {
    subfeature: 'Event Audit Features',
    starter: false,
    core: true,
    premium: true,
    value: 5
  },

  {
    subfeature: 'Manage Company Rewards Budget',
    starter: false,
    core: false,
    premium: true,
    value: 6
  }, {
    subfeature: 'Customize Rewards Gamification Goals',
    starter: false,
    core: false,
    premium: true,
    value: 6
  }, {
    subfeature: 'Downloabale Rewards Reports',
    starter: false,
    core: false,
    premium: true,
    value: 6
  }, {
    subfeature: 'View Employee Rewards Participation',
    starter: false,
    core: false,
    premium: true,
    value: 6
  }, {
    subfeature: 'Rewards Data Analysis',
    starter: false,
    core: false,
    premium: true,
    value: 6
  }, {
    subfeature: 'Track Rewards Spending',
    starter: false,
    core: false,
    premium: true,
    value: 6
  }, {
    subfeature: 'Upload Benefits Documents',
    starter: false,
    core: false,
    premium: true,
    value: 6
  },

  {
    subfeature: 'Aggregated Real-Time Biometric Data Analysis',
    starter: false,
    core: false,
    premium: true,
    value: 7
  }, {
    subfeature: 'Company Wellness Score',
    starter: false,
    core: false,
    premium: true,
    value: 7
  }, {
    subfeature: 'Review and Compare Historical Data',
    starter: false,
    core: false,
    premium: true,
    value: 7
  }, {
    subfeature: 'View Potential Health Risks',
    starter: false,
    core: false,
    premium: true,
    value: 7
  }, {
    subfeature: 'Analyze Biometrics',
    starter: false,
    core: false,
    premium: true,
    value: 7
  }, {
    subfeature: 'Track Employee Participation',
    starter: false,
    core: false,
    premium: true,
    value: 7
  }
]

class TableRowAdminFeature extends Component {
  constructor() {
    super();
    this.state = {
      open: false
    }
  }

  readMore = (value) => {
    this.setState({ open: !this.state.open, close: !this.state.close, dropdownOpen: value });
  }

  render() {
    const { item } = this.props;
    return (
      <div className='tableRow' >
        <div>
          {item.dropdown ?
            <button className='btn-main' onClick={() => this.readMore(item.value, item.type)}>
              {this.state.close && (item.value === this.state.dropdownOpen) ?
                <button className='drop-btn'><img src='/public/images/Polygondrop.svg' /></button>
                :
                <button className='drop-btn'><img src='/public/images/Polygon.svg' /></button>
              }
            </button> :
            <button className='btn-main' onClick={() => this.readMore(item.value, item.type)}>
              {this.state.close && (item.value === this.state.dropdownOpen) ?
                <div className='drop-btn-invisible' />
                :
                <div className='drop-btn-invisible' />
              }
            </button>
          }
          {item.feature}
        </div>
        <React.Fragment>
          <div>{item.starter ? <img src="/public/images/PricingPageImages/rightTick.png" /> : null}</div>
          <div>{item.core ? <img src="/public/images/PricingPageImages/rightTick.png" /> : null}</div>
          <div>{item.premium ? <img src="/public/images/PricingPageImages/rightTick.png" /> : null}</div>
        </React.Fragment>
        {this.state.close && (item.value === this.state.dropdownOpen) &&
          <div className='subcontent'>
            {
              subcontent.map((subfeature, index) => (
                subfeature.value == this.state.dropdownOpen &&
                <div className='tableRow subcontent-tableRow' key={index}>
                  <div className='dot-set'>
                    <img className='dot-img' src='/public/images/Dot.svg' />
                    <div className='dot-letter'>{subfeature.subfeature}</div>
                  </div>
                  <React.Fragment>
                    <div className='tick'>{subfeature.starter ? <img src="/public/images/PricingPageImages/rightTick.png" /> : null}</div>
                    <div className='tick'>{subfeature.core ? <img src="/public/images/PricingPageImages/rightTick.png" /> : null}</div>
                    <div className='tick'>{subfeature.premium ? <img src="/public/images/PricingPageImages/rightTick.png" /> : null}</div>
                  </React.Fragment>
                </div>
              ))
            }
          </div >
        }
      </div>
    )
  }
}

TableRowAdminFeature.propTypes = {
  item: PropTypes.object
};

export default TableRowAdminFeature