import React, { Component } from 'react'
import { Table, TableContainer } from "./style";
import PropTypes from 'prop-types';
import TableRowAdminFeature from './TableRowAdminFeature';

const wellness = [
  {
    feature: 'Company Dashboard',
    starter: true,
    core: true,
    premium: true,
    dropdown: 1,
    value: 1,
  }, {
    feature: 'Challenges Dashboard',
    starter: true,
    core: true,
    premium: true,
    dropdown: 1,
    value: 2,
  }, {
    feature: 'People Management',
    starter: true,
    core: true,
    premium: true,
    dropdown: 1,
    value: 3
  }, {
    feature: 'Health & Wellness Reports',
    starter: false,
    core: true,
    premium: true,
    dropdown: 1,
    value: 4
  }, {
    feature: 'Events Managemnet',
    starter: false,
    core: true,
    premium: true,
    dropdown: 1,
    value: 5
  },
  {
    feature: 'Company Rewards',
    starter: false,
    core: false,
    premium: true,
    dropdown: 1,
    value: 6
  },
  {
    feature: 'Biometric Reports',
    starter: false,
    core: false,
    premium: true,
    dropdown: 1,
    value: 7
  },
  {
    feature: 'Single Sign-On',
    starter: false,
    core: false,
    premium: true
  }, {
    feature: 'Dedicated Account Manager',
    starter: false,
    core: false,
    premium: true
  }, {
    feature: 'SMS Messaging ( coming soon )',
    starter: false,
    core: false,
    premium: true
  }

];
class TeamPackageTable extends Component {
  constructor() {
    super();
    this.state = {
      open: 0,
      close: false,
      dropdownOpen: null,
    }
  }
  readMore = (value) => {
    this.setState({ open: !this.state.open, close: !this.state.close, dropdownOpen: value });
  }
  render() {
    return (
      <TableContainer>
        <Table>
          {
            wellness.map((item, index) => (
              <TableRowAdminFeature item={item} key={index} />
            ))
          }
        </Table>
      </TableContainer>
    );
  }
}
TeamPackageTable.propTypes = {
  tableClass: PropTypes.string,
  headerClass: PropTypes.string
};

export default TeamPackageTable;