import React, { Component } from 'react'
import PropTypes from "prop-types";

const subcontent = [
  {
    subfeature: 'Recommended Weekly Wellness Activity',
    starter: true,
    core: true,
    premium: true,
    value: 1
  },
  {
    subfeature: 'Daily Wellness Tips and Nudges',
    starter: true,
    core: true,
    premium: true,
    value: 1
  },
  {
    subfeature: 'Fitness Device Integration',
    starter: true,
    core: true,
    premium: true,
    value: 1
  },
  {
    subfeature: 'Manually Log Workouts ',
    starter: true,
    core: true,
    premium: true,
    value: 1
  },
  {
    subfeature: 'Track Workout History ',
    starter: true,
    core: true,
    premium: true,
    value: 1
  },
  {
    subfeature: 'Recommended Company Initiatives',
    starter: true,
    core: true,
    premium: true,
    value: 1
  },
  {
    subfeature: 'Daily Fitness Activity',
    starter: true,
    core: true,
    premium: true,
    value: 1
  }, {
    subfeature: 'Company Featured Challenges',
    starter: true,
    core: true,
    premium: true,
    value: 2
  }, {
    subfeature: 'Recommended Personal Challenges',
    starter: true,
    core: true,
    premium: true,
    value: 2
  }, {
    subfeature: 'Track Completed Challenges',
    starter: true,
    core: true,
    premium: true,
    value: 2
  }, {
    subfeature: 'Wellness Behaviour Challenges',
    starter: true,
    core: true,
    premium: true,
    value: 2
  }, {
    subfeature: 'Track Your Yearly Fitness Score',
    starter: true,
    core: true,
    premium: true,
    value: 2
  }, {
    subfeature: 'Global Challenges',
    starter: true,
    core: true,
    premium: true,
    value: 2
  }, {
    subfeature: 'Group Challenges',
    starter: true,
    core: true,
    premium: true,
    value: 2
  }, {
    subfeature: 'Survivor Challenges',
    starter: true,
    core: true,
    premium: true,
    value: 2
  }, {
    subfeature: 'Leaderboard Challenges',
    starter: true,
    core: true,
    premium: true,
    value: 2
  }, {
    subfeature: 'Recommended Articles',
    starter: true,
    core: true,
    premium: true,
    value: 3
  }, {
    subfeature: 'Recommended Fitness Videos',
    starter: true,
    core: true,
    premium: true,
    value: 3
  }, {
    subfeature: 'Recommended Nutrition Recipes',
    starter: true,
    core: true,
    premium: true,
    value: 3
  }, {
    subfeature: 'On-demand Wellness Library',
    starter: true,
    core: true,
    premium: true,
    value: 3
  }, {
    subfeature: 'On-demand Health & Wellness Quizzes',
    starter: true,
    core: true,
    premium: true,
    value: 3
  }, {
    subfeature: 'On-demand Fitness Videos',
    starter: true,
    core: true,
    premium: true,
    value: 3
  }, {
    subfeature: 'On-demand Nutrition Recipes',
    starter: true,
    core: true,
    premium: true,
    value: 3
  }, {
    subfeature: 'Search On-demand Content',
    starter: true,
    core: true,
    premium: true,
    value: 3
  }, {
    subfeature: 'Favorite On-demand Content',
    starter: true,
    core: true,
    premium: true,
    value: 3
  }, {
    subfeature: 'Wellness Gamification',
    starter: true,
    core: true,
    premium: true,
    value: 3
  }, {
    subfeature: 'Track Your Wellness Points',
    starter: true,
    core: true,
    premium: true,
    value: 3
  }, {
    subfeature: 'Manage Your Buddies',
    starter: true,
    core: true,
    premium: true,
    value: 4
  }, {
    subfeature: 'Search Buddies',
    starter: true,
    core: true,
    premium: true,
    value: 4
  }, {
    subfeature: 'Recommended Buddies',
    starter: true,
    core: true,
    premium: true,
    value: 4
  }, {
    subfeature: 'Company Employee Directory',
    starter: true,
    core: true,
    premium: true,
    value: 4
  }, {
    subfeature: 'Track Total Points and History',
    starter: true,
    core: true,
    premium: true,
    value: 5
  }, {
    subfeature: 'Track Fitness Points and Activities',
    starter: true,
    core: true,
    premium: true,
    value: 5
  }, {
    subfeature: 'Track Social Points and Activities',
    starter: true,
    core: true,
    premium: true,
    value: 5
  }, {
    subfeature: 'Track Wellness Points and Activities',
    starter: true,
    core: true,
    premium: true,
    value: 5
  }, {
    subfeature: 'Earn Diffrent Level Badges',
    starter: true,
    core: true,
    premium: true,
    value: 5
  }, 
  // {
  //   subfeature: 'Watch Live Lunch & Learn Events',
  //   starter: false,
  //   core: true,
  //   premium: true,
  //   value: 6
  // }, 
  {
    subfeature: 'Access On-demand Wellness Events',
    starter: false,
    core: true,
    premium: true,
    value: 6
  }, {
    subfeature: 'Add Wellness Events to your Calendar',
    starter: false,
    core: true,
    premium: true,
    value: 6
  }, {
    subfeature: 'Track Wellness Events Points',
    starter: false,
    core: true,
    premium: true,
    value: 6
  }, {
    subfeature: 'View Completed Events',
    starter: false,
    core: true,
    premium: true,
    value: 6
  },]

class TableRowUserFeature extends Component {
  constructor() {
    super();
    this.state = {
      open: false
    }
  }

  readMore = (value) => {
    this.setState({ open: !this.state.open, close: !this.state.close, dropdownOpen: value });
  }

  render() {
    const { item } = this.props;
    return (
      <div className='tableRow' >
        <div>
          {item.dropdown ?
            <button className='btn-main' onClick={() => this.readMore(item.value, item.type)}>
              {this.state.close && (item.value === this.state.dropdownOpen) ?
                <button className='drop-btn'><img src='/public/images/Polygondrop.svg' /></button>
                :
                <button className='drop-btn'><img src='/public/images/Polygon.svg' /></button>
              }
            </button> :
            <button className='btn-main' onClick={() => this.readMore(item.value, item.type)}>
              {this.state.close && (item.value === this.state.dropdownOpen) ?
                <div className='drop-btn-invisible' />
                :
                <div className='drop-btn-invisible' />
              }
            </button>
          }
          {item.feature}
        </div>
        <React.Fragment>
          <div>{item.starter ? <img src="/public/images/PricingPageImages/rightTick.png" /> : null}</div>
          <div>{item.core ? <img src="/public/images/PricingPageImages/rightTick.png" /> : null}</div>
          <div>{item.premium ? <img src="/public/images/PricingPageImages/rightTick.png" /> : null}</div>
        </React.Fragment>
        {this.state.close && (item.value === this.state.dropdownOpen) &&
          <div className='subcontent'>
            {
              subcontent.map((subfeature, index) => (
                subfeature.value == this.state.dropdownOpen &&
                <div className='tableRow subcontent-tableRow' key={index}>
                  <div className='dot-set'>
                    <img className='dot-img' src='/public/images/Dot.svg' />
                    <div className='dot-letter'>{subfeature.subfeature}</div>
                  </div>
                  <React.Fragment>
                    <div className='tick'>{subfeature.starter ? <img src="/public/images/PricingPageImages/rightTick.png" /> : null}</div>
                    <div className='tick'>{subfeature.core ? <img src="/public/images/PricingPageImages/rightTick.png" /> : null}</div>
                    <div className='tick'>{subfeature.premium ? <img src="/public/images/PricingPageImages/rightTick.png" /> : null}</div>
                  </React.Fragment>
                </div>
              ))
            }
          </div >
        }
      </div>
    )
  }
}

TableRowUserFeature.propTypes = {
  item: PropTypes.object
};

export default TableRowUserFeature